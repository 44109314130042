<template>
    <div class="datumDetail">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <!-- <div class="datumDetail_nav">
            <span>在线题库  ></span><span>{{}}</span>
        </div> -->
        <div class="datumDetail_main">
            <div class="datumDetail_main_left">
                <div class="datumDetail_main_left_box">
                    <div class="datumDetail_main_left_box_item">
                        <div class="datumDetail_main_left_box_item_left">
                            
                            <img v-if="datumObj.filePath.substring(datumObj.filePath.lastIndexOf('.')+1) =='pdf'" src="@/assets/pdf.png" alt="">
                            <img v-else-if="datumObj.filePath.substring(datumObj.filePath.lastIndexOf('.')+1) =='doc' 
                            || datumObj.filePath.substring(datumObj.filePath.lastIndexOf('.')+1) =='docx'" src="@/assets/doc.png" alt="">
                            <img v-else-if="datumObj.filePath.substring(datumObj.filePath.lastIndexOf('.')+1) =='ppt'" src="@/assets/ppt.png" alt="">
                            <img v-else-if="datumObj.filePath.substring(datumObj.filePath.lastIndexOf('.')+1) =='xls' 
                                || datumObj.filePath.substring(datumObj.filePath.lastIndexOf('.')+1) =='xlsx'
                                || datumObj.filePath.substring(datumObj.filePath.lastIndexOf('.')+1) =='csv'" src="@/assets/xls.png" alt="">
                            <img v-else src="@/assets/weizhi.png" alt="">
                            <div class="datumDetail_main_left_box_item_left_content">
                                <p>
                                    <span class="tuijian" v-if="datumObj.isHot ==1">推荐</span>
                                    <span class="filename">{{datumObj.materialName}}</span>
                                </p>
                                <p>
                                    <span>{{datumObj.materialSize}}KB</span>
                                    <span>下载 {{datumObj.downloadCount}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="datumDetail_main_left_box_item_right">
                            <el-button plain @click="goHeaderHandout()">下载</el-button>
                        </div>
                    </div>
                    <div class="datumDetail_main_left_box_content">
                        <div class="datumDetail_main_left_box_content_header">资料简介</div>
                        <div class="datumDetail_main_left_box_content_text" v-html="datumObj.content"></div>
                    </div>
                </div>
                <div class="datumDetail_main_left_list">
                    <div class="datumDetail_main_left_list_header">相关资料</div>
                    <div class="datumDetail_main_left_list_item" v-for="(item,index) in relatedList" :key="index" @click="goHandouts(item)">
                        <div class="datumDetail_main_left_list_item_left">
                            <p>{{item.materialName}}</p>
                            <p>
                                <span>{{item.materialSize}}KB</span>
                                <span>下载数 {{item.downloadCount}}</span>
                            </p>
                        </div>
                        <div class="datumDetail_main_left_list_item_right"><el-button plain @click="goHandouts(item)">下载</el-button></div>
                    </div>
                </div>
            </div>
            <div class="datumDetail_main_right">
                <div class="datumDetail_main_right_freeLive" v-if="freeLiveList.length>0">
                    <div class="datumDetail_main_right_freeLive_header">
                        <span>免费直播</span>
                        <span>更多 ></span>
                    </div>
                    <div class="datumDetail_main_right_freeLive_list">
                        <div class="datumDetail_main_right_freeLive_list_item" v-for="(item,index) in freeLiveList" :key="index" >
                            <div class="datumDetail_main_right_freeLive_list_item_left">
                                <img :src="item.teacherHeaderPath" alt="">
                                <span>{{item.teacherName}}</span>
                            </div>
                            <div class="datumDetail_main_right_freeLive_list_item_right">
                                <div class="datumDetail_main_right_freeLive_list_item_right_top">
                                    <span v-if="item.type==0">直播中</span>
                                    <span v-else style="width:1px"></span>
                                    <span>{{item.liveTIme}}</span>
                                </div>
                                <div class="datumDetail_main_right_freeLive_list_item_right_bottom">
                                    <p style="width:80%;">
                                        <span style="text-align:left;">{{item.liveName}}</span>
                                    </p>
                                    <button style="cursor:pointer;" v-if="item.type==0" @click="toLiveDetail(item)">进入直播</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="datumDetail_main_right_bottom">
                    <div class="datumDetail_main_right_bottom_icon">
                        <p>
                            <img src="@/assets/ask/information_icon.png" alt="">
                            <span>备考干货</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/consult.png" alt="">
                            <span>财务资讯</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/alarm.png" alt="">
                            <span>实时互动</span>
                        </p>
                    </div>
                    <div class="datumDetail_main_right_bottom_QRcode">
                        <img src="@/assets/ask/QRcode.png" alt="">
                    </div>
                    <div class="datumDetail_main_right_bottom_text">下载APP，实时查看老师答疑</div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { material_firstType,material_secondType,material_list,related_list,add_download } from "@/api/other";
import { freelive_list } from "@/api/home";
export default {
    name:'datumDetail',
    components: {
        t_header,
        t_footer,
        // popup_login
    },
    data(){
        return{
            isSort:false,
            // todos:false,
            isType:false,
            datumObj:{},
            freeLiveList:[],
            relatedList:[],
            isUserInfo:false,
        }
    },
    mounted(){
        this.datumObj=JSON.parse(this.$route.query.item);
        if(this.datumObj.materialName){
            document.title = this.datumObj.materialName+'-萨恩课堂';
        }
        this.freelive_list()
        this.related_list()
    },
    methods:{
         // 去直播间
        toLiveDetail(item){
            // console.log(item)
            // var url='https://live.polyv.cn/watch/'+item.channelId
            // window.open(url, '_blank');
            
            let routeUrl = this.$router.resolve({
                path: '/liveVideo',
                query: {
                    channelId:item.channelId
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        // 父传子
        getTodos(e){
            this.isUserInfo=e
        },
        goHeaderHandout(){
          const userInfo = localStorage.getItem("setuserInfo");
            if(userInfo==null || userInfo == 'null'){
                this.isUserInfo=true
                return false
            }
            this.add_download({
                materialId:this.datumObj.materialId
            })
            // let routeData = this.$router.resolve({ path: this.datumObj.filePath, query: {} });
            window.open(this.datumObj.filePath, '_blank');
        },
        // 下载
        goHandouts(item){
            const userInfo = localStorage.getItem("setuserInfo");
            if(userInfo==null || userInfo == 'null'){
                this.isUserInfo=true
                return false
            }
            this.add_download({
                materialId:item.materialId
            })
            // let routeData = this.$router.resolve({ path: item.filePath, query: {} });
            window.open(item.filePath, '_blank');
        },
        // 增加下载量
        add_download(obj){
            add_download(obj).then(res=>{
                console.log(res)
                console.log('下载了')
            }).catch(error=>{
                console.log(error)
            })
        },
        // 免费直播列表
        freelive_list(){
            freelive_list().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    var arr = res.data.data.slice(0,4);
                    // console.log(arr);
                    this.freeLiveList=arr;
                }else{
                    this.$message.error(res.data.msg);
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // // 免费直播列表
        // freelive_list(){
        //     freelive_list().then(res=>{
        //         console.log(res)
        //         if(res.data.code==0){
        //             this.freeLiveList=res.data.data
        //         }else{
        //             this.$message.error(res.data.msg);
        //         }
        //     }).catch(error=>{
        //         console.log(error)
        //     })
        // },
        related_list(){
            related_list().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.relatedList=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
    }
}
</script>

<style>
    .tuijian{
        width: 44px;
        height: 18px;
        background: linear-gradient(90deg, #FFC26F 0%, #FF8E3C 100%);                                      
        border-radius: 100px 100px 100px 0px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
    .filename{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }
</style>